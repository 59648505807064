import { useEffect, useMemo, useRef, useState } from "react";
import "@agp/shared.common/extensions/number.extensions";
import { LinedText } from "../../../../../components/lined-text";
import ServiceLocator from "../../../../../../domain/service-locator";
import { IAudioSpeechTrack } from "@agp/shared.user-app/module/guide-player.module/audio-client";
import { MinpakuPlayerMiddleController } from "./minpaku-player-middle-controller";
import { twMerge } from "tailwind-merge";

const controllerHeight = 90;

const MinpakuPlayerMiddleComponent = () => {
  const theme = ServiceLocator.ThemeStore.theme;
  const guidePlayerModule = ServiceLocator.GuidePlayerModule;
  const playerLayoutModule = ServiceLocator.PlayerLayoutModule;

  const scrollRef = useRef<HTMLDivElement>(null);

  const track = guidePlayerModule.currentTrack.watch();
  const playerRectInfo = playerLayoutModule.playerRectInfo.watch();
  const upperSegmentHeight = useMemo(
    () => playerRectInfo.middlePlayerHeight - controllerHeight,
    [playerRectInfo]
  );
  const playerType = playerLayoutModule.playerType.watch();

  const [isBigText, setIsBigText] = useState(false);

  useEffect(() => {
    scrollRef.current?.scrollTo({ top: 0 });
  }, [track?.trackNumber]);

  return (
    <>
      {track && (
        <>
          <div
            className={twMerge(
              "will-change-transform hidden-scrollbar relative",
              playerType === "full" ? "overflow-y-scroll" : "overflow-y-hidden"
            )}
            id="MiddlePlayer"
            ref={scrollRef}
          >
            <div
              style={{ height: upperSegmentHeight }}
              onClick={() => playerLayoutModule.playerType.setValue("mini")}
            >
              <img
                src={
                  track.thumbnailUrls && track.thumbnailUrls.length > 0
                    ? track.thumbnailUrls[0]
                    : "no-image.png"
                }
              />
              <div
                className={"px-6 mt-10 text-3xl h-20"}
              >{`${track.trackNumber}. ${track.name}`}</div>
            </div>
            <div className={"flex justify-end items-end gap-2 px-6"}>
              <div
                className={twMerge()}
                style={{ color: !isBigText ? theme.color.primary : "" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsBigText(false);
                }}
              >
                A
              </div>
              <div
                className={twMerge("text-3xl")}
                style={{ color: isBigText ? theme.color.primary : "" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsBigText(true);
                }}
              >
                A
              </div>
            </div>
            <Description isBigText={isBigText} />
          </div>
          <MinpakuPlayerMiddleController height={controllerHeight} />
          <div
            className={"fixed top-[10px] left-[50%] -translate-x-1/2"}
            onClick={() => playerLayoutModule.playerType.setValue("mini")}
          >
            <div
              className={
                "absolute bg-black rounded-full w-8 h-8 top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
              }
            />
            <img
              alt="expandable-button"
              src="/minpaku-demo/icons/expand-button.svg"
              className={`h-9 w-9 rotate-0`}
            />
          </div>
        </>
      )}
    </>
  );
};

export default MinpakuPlayerMiddleComponent;

const Description = ({ isBigText }: { isBigText: boolean }) => {
  const guidePlayerModule = ServiceLocator.GuidePlayerModule;
  const playerLayoutModule = ServiceLocator.PlayerLayoutModule;
  const { theme } = ServiceLocator.ThemeStore;

  const playerMode = guidePlayerModule.playerMode.watch();
  const track = guidePlayerModule.currentTrack.watch() as IAudioSpeechTrack;
  const currentSeek = guidePlayerModule.currentSeek.watch();
  const playerRectInfo = playerLayoutModule.playerRectInfo.watch();

  const descriptionWrapperHeight = useMemo(
    () => playerRectInfo.fullPlayerHeight - playerRectInfo.middlePlayerHeight,
    [playerRectInfo]
  );

  return playerMode === "AudioSynthesis" ? (
    <div className={"px-6"} style={{ height: descriptionWrapperHeight }}>
      {[...track.text].map((x, i) => (
        <span
          style={{
            color:
              i < currentSeek
                ? theme.color.primaryText
                : theme.color.secondaryText,
          }}
          key={`sentence-${i}`}
          onClick={() => guidePlayerModule.seek(i)}
        >
          {x === "\n" ? <br /> : x}
        </span>
      ))}
      <div className={"h-[30px]"} />
    </div>
  ) : (
    <div
      className={twMerge(
        "px-6",
        isBigText ? "text-3xl leading-10" : "text-xl/9"
      )}
      style={{ height: descriptionWrapperHeight }}
    >
      <LinedText text={track.text} />
      <div className={"h-[40px]"} />
    </div>
  );
};
