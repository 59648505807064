import { useCallback } from "react";
import { useNavigationModule } from "@agp/shared.user-app/application/context/navigation";

export const MinpakuNote = () => {
  const { push } = useNavigationModule();

  const handleClick = useCallback(() => {
    push(
      "info",
      {},
      {
        pageInType: "fade",
        pageOutType: "fade",
      }
    );
  }, []);

  return (
    <div className="bg-white pt-5 h-[calc(var(--vh,1vh)*100)] text-black pb-8 flex flex-col gap-5">
      <img
        src="/minpaku-demo/logos/minpaku.svg"
        className="mx-auto h-28 flex-shrink-0 flex-grow-0"
      />
      <div className="flex-grow flex justify-center flex-col pb-28">
        <div className="text-center text-[#762100] font-bold text-4xl">
          お願い
        </div>
        <div className="grid grid-cols-1 gap-10 ml-10 mt-16">
          <NoteSentence
            text={"音声をお聞きになる際には、\nイヤホンをご利用ください"}
          />
          <NoteSentence
            text={
              "展示室内では、ほかのお客様や\n展示作品との間隔をあけ、ご利用\nください"
            }
          />
        </div>
      </div>
      <div className={"absolute bottom-10 right-10"}>
        <div
          className={
            "rounded-full w-24 h-24 bg-white text-black text-center content-center text-3xl font-bold border-[1px] border-black"
          }
          onClick={handleClick}
        >
          次へ
        </div>
      </div>
    </div>
  );
};

const NoteSentence = (props: { text: string }) => {
  const split = props.text.split("\n");
  return (
    <div className="font-bold text-2xl">
      <span className="text-[#762100]">◉</span>
      {split[0]}
      {split.length > 1 &&
        split
          .filter((_, i) => i > 0)
          .map((x) => <div className="ml-5">{x}</div>)}
    </div>
  );
};
