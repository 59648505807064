import React, { useCallback, useState } from "react";
import ServiceLocator from "../../../../../domain/service-locator";
import { QuestionnaireFormModal } from "./questionnaire-form-modal";
import { QuestionnaireCompleteSubmitModal } from "./questionnaire-complete-submit-modal";
import { AcoustiGuideQuestionnaireData } from "./acousti-guide-questionnaire-data";

export const QuestionnaireCard = () => {
  const activityLogModule = ServiceLocator.ActivityLogModule;
  const questionnaireRepository = ServiceLocator.QuestionnaireRepository;
  const savedQuestionnaireData =
    questionnaireRepository.savedQuestionnaireData.watch();

  const [openCompleteModal, setOpenCompleteModal] = useState(false);
  const [openQuestionnaireModal, setOpenQuestionnaireModal] = useState(false);

  const handleSubmit = useCallback(
    (data: AcoustiGuideQuestionnaireData) => {
      activityLogModule.setUserProperty({
        age: data.age,
        job: data.job,
      });
      activityLogModule.sendQuestionnaireEvent(data);
      questionnaireRepository.saveQuestionnaireData(data);
      setOpenQuestionnaireModal(false);
      setOpenCompleteModal(true);
    },
    [activityLogModule, questionnaireRepository]
  );

  return (
    <>
      {savedQuestionnaireData == null && (
        <div
          className={
            "relative w-full aspect-[4/1] overflow-hidden text-white text-3xl flex items-center justify-between pr-4 pl-10 font-bold transition-all duration-300 rounded-3xl bg-[#bb5730]"
          }
          onClick={() => {
            setOpenQuestionnaireModal(true);
          }}
        >
          アンケートのご協力
        </div>
      )}
      <QuestionnaireFormModal
        open={openQuestionnaireModal}
        onClose={() => setOpenQuestionnaireModal(false)}
        onSubmit={handleSubmit}
      />
      <QuestionnaireCompleteSubmitModal
        open={openCompleteModal}
        onClose={() => setOpenCompleteModal(false)}
      />
    </>
  );
};
