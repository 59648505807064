import { useCallback } from "react";
import { useNavigationModule } from "@agp/shared.user-app/application/context/navigation";

export const MinpakuDescription = () => {
  const { push } = useNavigationModule();
  const handleClick = useCallback(() => {
    push(
      "note",
      {},
      {
        pageInType: "fade",
        pageOutType: "fade",
      }
    );
  }, []);

  return (
    <div
      className="bg-[#210917] pt-5 h-[calc(var(--vh,1vh)*100)] text-white pb-8 flex flex-col gap-5"
      onClick={handleClick}
    >
      <img
        src="/minpaku-demo/top/logos/minpaku.svg"
        className="mx-auto h-28 flex-shrink-0 flex-grow-0"
      />
      <div className="ml-8 flex flex-col justify-evenly flex-grow">
        <div className="grid grid-cols-[8rem_1fr] gap-6">
          <div className="text-[#fbae3c] text-center font-bold text-xl ">
            使いかた
          </div>
          <div className="font-bold text-xl ">
            解説を聞きたい地域を選択すると、
            <br />
            一覧が表示されます
          </div>
          <div className="text-sm text-center mt-3 -mb-3">メニューボタン</div>
          <div />
          <IconDescription
            title="リスト"
            iconUrl="/minpaku-demo/icons/list-icon-active.svg"
            description={
              "音声ガイドの一覧が表示されます。\nお聞きになりたいガイドを選択し、\n再生ボタンを押してください。"
            }
          />
          <IconDescription
            title="マップ"
            iconUrl="/minpaku-demo/icons/map-active.svg"
            description={"解説エリアのマップが\n表示されます"}
          />
          <IconDescription
            title="キーパッド"
            iconUrl="/minpaku-demo/icons/keypad-active.svg"
            description={
              "ガイド番号を入力すると、\n再生画面が表示されます。\n再生ボタンを押すと、\n解説を聞くことが出来ます。"
            }
          />
        </div>
        <div className="mr-8">
          <div className="text-sm w-32 text-center">音声ガイド番号表示</div>
          <div className="flex justify-between mt-6 items-center">
            <div className="flex flex-col gap-4 items-center">
              <div className="aspect-[4/3] w-32 bg-white text-black flex justify-center items-center text-[4rem] font-bold">
                1
              </div>
            </div>
            <div className="rounded-full bg-white text-[#3e3a39] aspect-square flex justify-center items-center h-20 text-2xl font-bold">
              次へ
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const IconDescription = (props: {
  title: string;
  iconUrl: string;
  description: string;
}) => (
  <>
    <div className="flex justify-center items-center">
      <img src={props.iconUrl} className="w-20" />
    </div>
    <div>
      <div className="text-2xl font-bold mb-1 text-[#cacaca]">
        {props.title}
      </div>
      <div className="text-md">
        {props.description.split("\n").map((x, i) => (
          <>
            {i > 0 && <br />}
            {x}
          </>
        ))}
      </div>
    </div>
  </>
);
