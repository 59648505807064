import { getQuery } from "../query";
import { IApplicationConfig } from "@agp/shared.user-app/infrastructure/application-config";

const apiUrl = import.meta.env.VITE_API_URL;

const getApplicationId = () => {
  const value =
    import.meta.env.MODE !== "production"
      ? getQuery("applicationId")
      : import.meta.env.VITE_APPLICATION_ID;
  if (!value) throw new Error();
  return value;
};

export const applicationConfig: IApplicationConfig = {
  apiUrl,
  applicationId: getApplicationId(),
};
