import ServiceLocator from "../../../../../domain/service-locator";
import { useNavigationModule } from "@agp/shared.user-app/application/context/navigation";
import { useCallback, useMemo } from "react";

export const MinpakuTop = () => {
  const localizedEntityRepository = ServiceLocator.LocalizedEntityRepository;

  const { applicationConfig } = ServiceLocator.MasterDataModule.masterData;
  const navigation = useNavigationModule();

  /**
   * useMemo
   */
  const entities = localizedEntityRepository.entities.watch();
  const defaultArea = useMemo(
    () => entities.areas.find((x) => x.isDefault),
    [entities]
  );
  const handleClickStartButton = useCallback(() => {
    if (!applicationConfig) return;
    navigation.push(
      "note",
      {},
      {
        pageOutType: "fade",
        pageInType: "fade",
      }
    );
  }, [applicationConfig, defaultArea]);

  return (
    <div className={"w-full h-full bg-white bg-cover bg-center relative"}>
      <div className={"absolute inset-0 bg-white opacity-50"} />
      <UpperArea />
      <LowerArea onClick={handleClickStartButton} />
    </div>
  );
};

const UpperArea = () => {
  return (
    <div className={"relative w-full h-1/2"}>
      <img
        src={"/minpaku-demo/top/object1.svg"}
        className={"absolute top-10 -right-16 h-[120px]"}
        alt={"object1"}
      />
      <img
        src={"/minpaku-demo/top/object3.svg"}
        className={"absolute bottom-0 -left-7 w-[30%]"}
        alt={"object1"}
      />
      <img
        src={"/minpaku-demo/top/object2.svg"}
        className={"absolute bottom-0 right-0 w-[62%]"}
        alt={"object1"}
      />
      <img
        src={"/minpaku-demo/top/object4.svg"}
        className={"absolute top-0 left-0 w-[80%]"}
        alt={"object1"}
      />
      <div
        className={
          "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex flex-col items-center justify-center gap-2"
        }
      >
        <img
          src={"/minpaku-demo/top/minnpaku.svg"}
          className={"w-[50%]"}
          alt={"minpaku"}
        />
        <img
          src={"/minpaku-demo/top/onsei-guide-black.svg"}
          className={"w-[60%]"}
          alt={"guide"}
        />
      </div>
    </div>
  );
};

const LowerArea = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className={"w-full h-1/2 relative bg-[#e5e5e5]"} onClick={onClick}>
      <div
        className={
          "absolute inset-0 bg-gradient-to-t from-white via-white via-50% to-transparent"
        }
      />
      <div
        className={
          "w-full h-1/2 absolute top-0 pt-2 bg-gradient-to-r from-white to-transparent"
        }
      >
        <div className={"w-full flex justify-center gap-4 items-center mb-8"}>
          <img src={"/minpaku-demo/top/top-start.png"} className={"w-[70%]"} />
        </div>
      </div>
      <img
        src={"/minpaku-demo/top/object5.svg"}
        className={"absolute light-0 top-1/2 w-[100px]"}
        alt={"object5"}
      />
      <div
        className={
          "w-full h-1/2 absolute bottom-0 flex justify-center items-end pb-10"
        }
      >
        <img
          src={"/minpaku-demo/top/minpaku_logo.png"}
          className="mx-auto w-[70%]"
          alt={"minpaku_logo"}
        />
      </div>
    </div>
  );
};
