import {
  AudioPlaybackRate,
  IAudioSpeechClient,
  IAudioSpeechTrack,
} from "@agp/shared.user-app/module/guide-player.module/audio-client";
import { ReactivePropertyInstance } from "@agp/shared.common/use-reactive-property";

export abstract class AudioSpeechClientBase implements IAudioSpeechClient {
  public currentLibrary: IAudioSpeechTrack[] | null;
  public readonly currentTrack =
    new ReactivePropertyInstance<IAudioSpeechTrack | null>(null);
  public readonly isSeeking = new ReactivePropertyInstance<boolean>(false);
  public readonly playbackRate =
    new ReactivePropertyInstance<AudioPlaybackRate>(1);

  public get currentSeek() {
    return this.spokedCharacters.select((x) => x + this.characterOffset);
  }

  public readonly isPlaying = new ReactivePropertyInstance<boolean>(false);

  private isInitialized = false;
  protected voices: SpeechSynthesisVoice[] = [];
  protected currentUtterance: SpeechSynthesisUtterance;
  protected readonly spokedCharacters = new ReactivePropertyInstance<number>(0);
  protected characterOffset = 0;

  public constructor() {
    this.initializeAsync();
  }

  public checkCanUse = () => {
    return typeof window !== "undefined" && window.speechSynthesis;
  };

  public abstract play(): void;

  public abstract pause(): void;

  public seek = (to: number) => {
    if (!this.currentTrack.value) return;
    this.isSeeking.setValue(true);
    window.speechSynthesis.cancel();
    this.currentUtterance.text = this.currentTrack.value.text.slice(to);
    this.characterOffset = to;
    this.spokedCharacters.setValue(0);
    window.speechSynthesis.speak(this.currentUtterance);

    // 2024/09/09 Android-Chrome にて onresulme が上手く機能しないため、play() / pause() 側でステートを変更する臨時実装
    this.isPlaying.setValue(true);
    this.isSeeking.setValue(false);
  };

  public selectTrack = (trackId: string) => {
    if (this.isPlaying.value) this.reset();
    if (!this.currentLibrary)
      throw new Error("ライブラリがセットされていません");
    const track = this.currentLibrary.find((x) => x.id === trackId);
    if (!track)
      throw new Error(`TrackId ${trackId} のトラックが library 内にありません`);

    this.currentUtterance = this.prepareUtterance(track);
    this.currentTrack.setValue(track);
    this.characterOffset = 0;
    this.spokedCharacters.setValue(0);

    window.speechSynthesis.speak(this.currentUtterance);
  };

  public reset = () => {
    this.pause();
    window.speechSynthesis.cancel();
    this.currentTrack.setValue(null);
    this.characterOffset = 0;
    this.spokedCharacters.setValue(0);
  };

  public setLibrary = (library: IAudioSpeechTrack[]) =>
    (this.currentLibrary = library);

  public initializeAsync = async () => {
    if (this.isInitialized) return;
    if (!this.checkCanUse())
      throw new Error("この端末では音声読み上げ機能が使えません");
    this.voices = await this.getVoices();
    this.isInitialized = true;
  };

  public setPlaybackRate = (rate: AudioPlaybackRate) => {
    this.playbackRate.setValue(rate);
    // TODO: Implement
  };

  private getVoices = async () =>
    new Promise<SpeechSynthesisVoice[]>((result) => {
      window.speechSynthesis.onvoiceschanged = () => {
        result(window.speechSynthesis.getVoices());
      };
      window.speechSynthesis.getVoices();
    });

  private prepareUtterance(track: IAudioSpeechTrack): SpeechSynthesisUtterance {
    const utterance = new window.SpeechSynthesisUtterance();
    utterance.onend = () => this.isPlaying.setValue(false);
    utterance.onpause = () => this.isPlaying.setValue(false);
    utterance.onstart = () => this.isPlaying.setValue(true);
    utterance.onresume = () => this.isPlaying.setValue(true);
    utterance.onboundary = (e) =>
      this.spokedCharacters.setValue((x) => {
        const spoked = e.charIndex + e.charLength;
        return spoked > x ? spoked : x;
      });
    utterance.onerror = (e) => console.error(e);
    const trackLang = track.lang.split("-")[0].toLowerCase();
    const availableVoices = this.voices.filter(
      (x) => x.localService && x.lang.toLowerCase().includes(trackLang)
    );
    if (availableVoices.length === 0)
      throw new Error("使用できる音声読み上げようの voice がありません");

    utterance.voice = availableVoices[0];
    utterance.text = track.text;
    utterance.lang = availableVoices[0].lang;
    return utterance;
  }
}
