import { useNavigationModule } from "@agp/shared.user-app/application/context/navigation";
import ServiceLocator from "../../../../domain/service-locator";
import { ReactNode, useCallback, useMemo } from "react";
import { LinedText } from "../../../components/lined-text";

export const MinpakuInfo = () => {
  const { push } = useNavigationModule();

  const localizedEntityRepository = ServiceLocator.LocalizedEntityRepository;
  const entities = localizedEntityRepository.entities.watch();
  const defaultArea = useMemo(
    () => entities.areas.find((x) => x.isDefault),
    [entities]
  );

  const handleClick = useCallback(() => {
    push(
      "tours",
      { areaId: defaultArea!.id },
      {
        pageInType: "fade",
        pageOutType: "fade",
      }
    );
  }, []);

  return (
    <div className="bg-white h-[calc(var(--vh,1vh)*100)] text-black pb-8 flex flex-col gap-5 overflow-y-auto hidden-scrollbar">
      <img
        src="/minpaku-demo/logos/minpaku.svg"
        className="h-20 absolute top-10 left-10"
      />
      <div className="flex-grow flex flex-col pb-280 justify-center">
        <div
          className={
            "flex justify-center gap-10 max-w-[348px] pl-4 mr-auto ml-auto"
          }
        >
          <div className={"w-24"} />
          <div className={"flex flex-col font-bold text-2lg gap-4"}>
            <div className="text-[#762100] font-bold text-4xl">使いかた</div>
            <div className={"text-lg"}>
              解説を聞きたい地域を選択すると、
              <br />
              一覧が表示されます。
            </div>
          </div>
        </div>
        <div className={"flex flex-col max-w-[348px] mr-auto ml-auto gap-8"}>
          <div className={"flex-col mt-6 gap-4"}>
            <MenuInfo
              logoElement={() => (
                <img
                  src={"/minpaku-demo/icons/list-icon-gray-active.png"}
                  alt=""
                  className={"w-16"}
                />
              )}
              title={"リスト"}
              description={
                "音声ガイドの一覧が表示されます。\nお聞きになりたいガイドを選択し、\n再生ボタンを押してください。"
              }
            />
          </div>
          <MenuInfo
            logoElement={() => (
              <img
                src={"/minpaku-demo/icons/map-icon-gray-active.png"}
                alt=""
                className={"w-16"}
              />
            )}
            title={"マップ"}
            description={
              "解説エリアのマップが表示されます。お聞きになりたい番号を選択し、再生ボタンを押してください。"
            }
          />
          <MenuInfo
            logoElement={() => (
              <div className={"flex flex-col items-center gap-2"}>
                <img
                  src={"/minpaku-demo/icons/keypad-icon-gray-active.png"}
                  alt=""
                  className={"w-16"}
                />
                <div className={"border-[1px] border-black"}>
                  <img
                    src={"/minpaku-demo/icons/guide-number-1.png"}
                    alt=""
                    className={"w-16"}
                  />
                </div>
                <div className={"w-52 text-sm text-center"}>
                  音声ガイド番号表示
                </div>
              </div>
            )}
            title={"キーパッド"}
            description={
              "ガイド番号を入力すると、\n再生画面が表示されます。\n再生ボタンを押すと、\n解説を開くことができます。"
            }
          />
        </div>
        <div className={"absolute bottom-10 right-10"}>
          <div
            className={
              "rounded-full w-24 h-24 bg-white text-black text-center content-center text-3xl font-bold border-[1px] border-black"
            }
            onClick={handleClick}
          >
            次へ
          </div>
        </div>
      </div>
    </div>
  );
};

type MenuInfoProps = {
  title: string;
  description: string;
  logoElement: () => ReactNode;
};

const MenuInfo = (props: MenuInfoProps) => {
  return (
    <div className={"flex gap-10 pl-4 pt items-start"}>
      <div
        className={"w-24 h-24 flex justify-center items-start flex-shrink-0"}
      >
        {props.logoElement()}
      </div>
      <div className={"flex-col"}>
        <div className={"font-bold"}>{props.title}</div>
        <div>
          <LinedText text={props.description} />
        </div>
      </div>
    </div>
  );
};
