import PlayingAnimation from "../../guides/playing-animation";
import { Divider } from "../../../components/divider";
import { Guide } from "@agp/shared.user-app/entity";
import ServiceLocator from "../../../../domain/service-locator";
import { twMerge } from "tailwind-merge";

const getGuideNumberImagePath = (trackNumber: number) =>
  `/minpaku-demo/guide-number/${trackNumber}_list_200×150_minpaku_number.png`;

const MinpakuGuideItem = (props: {
  onClickGuide: (id: string) => void;
  guide: Guide;
  active: boolean;
  isListened: boolean;
}) => {
  const isPlaying = ServiceLocator.GuidePlayerModule.isPlaying.watch();
  return (
    <>
      <div
        className="cursor-pointer"
        onClick={() => props.onClickGuide(props.guide.id)}
      >
        <div className="flex items-center h-24">
          <div className={"p-2 flex-shrink-0"}>
            <img
              src={getGuideNumberImagePath(props.guide.trackNumber)}
              className={"h-20"}
            />
          </div>
          <div className="flex-grow">
            <div className="flex items-center justify-between gap-4 pl-4 pr-4">
              <div className="flex flex-shrink items-center gap-2">
                {props.active && <PlayingAnimation active={isPlaying} />}
                <div
                  className={twMerge(
                    "text-xl overflow-clip line-clamp-3",
                    props.isListened && "font-bold"
                  )}
                >
                  {props.guide.name}
                </div>
              </div>
              <div className={"relative flex-shrink-0"}>
                <div
                  className={
                    "absolute bg-[#a39323] rounded-full w-10 h-10 top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
                  }
                />
                <img
                  alt="expandable-button"
                  src="/minpaku-demo/icons/expand-button.svg"
                  className={`h-12 w-12 -rotate-90`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider />
    </>
  );
};

export default MinpakuGuideItem;
