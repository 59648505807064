import ServiceLocator from "../../../domain/service-locator";
import React, { useMemo } from "react";
import { LocalMap } from "./local-map";
import { MinpakuHeader } from "../../components/layout/header/minpaku.header";

/**
 * エリアローカルマップ
 * TODO: 正式採用後、エリアにマップURL機能を持たせ、そのURLを使うよう変更予定
 * @constructor
 */
export const AreaLocalMap = () => {
  /**
   * Prepare Modules
   */
  const localizedEntityRepository = ServiceLocator.LocalizedEntityRepository;
  const applicationStateRepository = ServiceLocator.ApplicationStateRepository;

  /**
   * watch ReactiveProperty
   */

  const entities = localizedEntityRepository.entities.watch();
  const currentAreaId = applicationStateRepository.currentAreaId.watch();
  const guides = useMemo(() => {
    const tours = entities.tours.filter((x) => x.areaId === currentAreaId);
    return entities.guides.filter((x) =>
      tours.map((x) => x.id).includes(x.tourId)
    );
  }, [currentAreaId, entities.guides, entities.tours]);

  return (
    <div className={"h-[100vh] bg-white"}>
      <MinpakuHeader />
      <LocalMap
        guides={guides}
        mapImageUrl={"/minpaku-demo/5area_map_minpaku.png"}
      />
    </div>
  );
};
