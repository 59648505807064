import ServiceLocator from "../../../../../../domain/service-locator";
import styled from "styled-components";
import Icon from "../../../../../components/icon";
import { useCallback } from "react";
import { MinpakuSeekBar } from "../minpaku-seek-bar";

export const MinpakuPlayerMiddleController = ({
  height,
}: {
  height: number;
}) => {
  const guidePlayerModule = ServiceLocator.GuidePlayerModule;
  const playerLayoutModule = ServiceLocator.PlayerLayoutModule;

  const isPlaying = guidePlayerModule.isPlaying.watch();
  const canNext = guidePlayerModule.canNext.watch();
  const canPrev = guidePlayerModule.canPrev.watch();
  const currentSeek = guidePlayerModule.currentSeek.watch();
  const currentPlaybackRate = guidePlayerModule.playbackRate.watch();
  const playerHeight = playerLayoutModule.playerHeight.watch();

  const handlePrev = useCallback(() => {
    if (currentSeek > 5) {
      guidePlayerModule.seek(0);
      return;
    }

    if (!canPrev) {
      guidePlayerModule.seek(0);
      return;
    }
    guidePlayerModule.prev();
  }, [canPrev, currentSeek, guidePlayerModule]);

  const handleNext = useCallback(() => {
    if (!canNext) return;
    guidePlayerModule.next();
  }, [canNext, guidePlayerModule]);

  const handleClickSpeedRateButton = useCallback(() => {
    switch (currentPlaybackRate) {
      case 1.0:
        guidePlayerModule.setPlaybackRate(1.25);
        return;
      case 1.25:
        guidePlayerModule.setPlaybackRate(1.5);
        return;
      case 1.5:
        guidePlayerModule.setPlaybackRate(2.0);
        return;
      case 2.0:
        guidePlayerModule.setPlaybackRate(1.0);
        return;
      default:
        return;
    }
  }, [currentPlaybackRate, guidePlayerModule]);

  const controllerHeight = `h-[${height}px]`;

  return (
    <div
      className={`fixed top-0 w-full pointer-events-none`}
      style={{ height: `${playerHeight}px` }}
    >
      <div
        className={`w-full ${controllerHeight} absolute bottom-0 bg-[#b8b2b8] flex flex-col py-4 px-10 pointer-events-auto`}
      >
        <MinpakuSeekBar withTime={true} />
        <div
          className={
            "flex items-center gap-1 absolute bottom-4 left-4 border-black border-[1px] rounded-full px-2"
          }
          onClick={handleClickSpeedRateButton}
        >
          <Icon type={"play"} className={"w-[8px]"} color={"black"} />
          {currentPlaybackRate.toFixed(2)} x
        </div>
        <div className={"flex justify-center items-center gap-6"}>
          <div className={"flex items-center gap-4"} onClick={handlePrev}>
            <img
              src={"/minpaku-demo/icons/left-arrow.svg"}
              width={15}
              alt={"leftArrow"}
            />
            <div className={"text-xl font-bold"}>前へ</div>
          </div>
          <BigIcon
            type={isPlaying ? "pause" : "play"}
            onClick={(e: any) => {
              e.stopPropagation();
              guidePlayerModule.playOrPause();
            }}
            color={"black"}
          />
          <div className={"flex items-center gap-4"} onClick={handleNext}>
            <div className={"text-xl font-bold"}>次へ</div>
            <img
              src={"/minpaku-demo/icons/right-arrow.svg"}
              width={15}
              alt={"rightArrow"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const BigIcon = styled(Icon)`
  width: 28px;
`;
