import { useCallback, useMemo } from "react";
import ServiceLocator from "../../../../domain/service-locator";
import { useNavigationModule } from "@agp/shared.user-app/application/context/navigation";
import { useLocation } from "react-router-dom";

type PageType = "list" | "map" | "keypad";

export const MinpakuHeader = () => {
  const { push } = useNavigationModule();
  const location = useLocation();

  const localizedEntityRepository = ServiceLocator.LocalizedEntityRepository;
  const entities = localizedEntityRepository.entities.watch();
  const defaultArea = useMemo(
    () => entities.areas.find((x) => x.isDefault),
    [entities]
  );

  const currentPageType: PageType = useMemo(() => {
    switch (location.pathname) {
      case "/tours":
        return "list";
      case "/map":
        return "map";
      case "/keypad":
        return "keypad";
      default:
        return "list";
    }
  }, [location.pathname]);

  const handleClickList = useCallback(() => {
    push(
      "tours",
      { areaId: defaultArea!.id },
      {
        pageInType: "none",
        pageOutType: "none",
      }
    );
  }, []);

  const handleClickMap = useCallback(() => {
    push(
      "map",
      { areaId: defaultArea!.id },
      {
        pageInType: "none",
        pageOutType: "none",
      }
    );
  }, []);

  const handleClickKeypad = useCallback(() => {
    push(
      "keypad",
      { areaId: defaultArea!.id },
      {
        pageInType: "none",
        pageOutType: "none",
      }
    );
  }, []);

  const handleClickAppIcon = useCallback(() => {
    push(
      "top",
      {},
      {
        pageInType: "fade",
        pageOutType: "fade",
      }
    );
  }, []);

  return (
    <>
      <div className="h-22 bg-white flex justify-between items-center fixed top-0 left-0 right-0 bg-[7] pt-4 pl-8 pr-4 pb-2 z-10">
        <img
          src="/minpaku-demo/logos/minpaku.svg"
          className={"h-16"}
          onClick={handleClickAppIcon}
        />
        <div className="flex gap-1">
          <div className={"w-16"} onClick={handleClickList}>
            <HeaderIcon
              iconUrl={`/minpaku-demo/icons/list-icon-gray${
                currentPageType === "list" ? "-active" : ""
              }.svg`}
              text="リスト"
            />
          </div>
          <div className={"w-16"} onClick={handleClickMap}>
            <HeaderIcon
              iconUrl={`/minpaku-demo/icons/map-icon-gray${
                currentPageType === "map" ? "-active" : ""
              }.svg`}
              text="マップ"
            />
          </div>
          <div className={"w-16"} onClick={handleClickKeypad}>
            <HeaderIcon
              iconUrl={`/minpaku-demo/icons/keypad-icon-gray${
                currentPageType === "keypad" ? "-active" : ""
              }.svg`}
              text="キーパッド"
            />
          </div>
        </div>
      </div>
      <div className="h-20 w-full flex-shrink-0" />
    </>
  );
};

const HeaderIcon = (props: { iconUrl: string; text: string }) => (
  <div className="flex flex-col gap-2 justify-center items-center">
    <img src={props.iconUrl} className="h-10" />
    <div className="text-xs text-black h-4 text-center">{props.text}</div>
  </div>
);
