export type TUiTemplate = "General" | "Minpaku";

const applicationIdTemplateMap: Record<string, TUiTemplate> = {
  "08dcc024-e8ac-4fc3-81bd-b80fd82d7f83": "Minpaku",
};

export class UiTemplateModule {
  public readonly uiTemplate: TUiTemplate;

  public constructor(applicationId: string) {
    this.uiTemplate = applicationId
      ? applicationIdTemplateMap[applicationId] || "General"
      : "General";
  }
}
