import { Keypad } from "../../../components/layout/keypad";
import { MinpakuHeader } from "../../../components/layout/header/minpaku.header";

export const MinpakuKeypad = () => {
  return (
    <div className="bg-white pt-5 h-[calc(var(--vh,1vh)*100)] text-white pb-8 flex flex-col">
      <MinpakuHeader />
      <Keypad onComplete={() => {}} />
    </div>
  );
};
