import { LocalizedEntityRepository } from "@agp/shared.user-app/module/localized-entity.repository";
import { GuidePlayerModule } from "@agp/shared.user-app/module/guide-player.module";
import { LanguageRepository } from "@agp/shared.user-app/module/language.repository";
import { ThemeStore } from "@agp/shared.user-app/module/theme.store";
import { ApplicationStateRepository } from "@agp/shared.user-app/module/application-state.repository";
import { PlayerLayoutModule } from "./module/player-layout/player-layout.module";
import { PrefetchModule } from "./module/prefetch";
import { UserAppApiModule } from "@agp/shared.user-app/module/api.module";
import { applicationConfig } from "../infrastructure/application-config";
import { MasterDataModule } from "@agp/shared.user-app/module/master-data.store";
import { previewConfig } from "../infrastructure/preview-config";
import { colorSchemaConfig } from "../infrastructure/color-schema-config";
import { PersistentDataModule } from "@agp/shared.user-app/module/persistent-data.module";
import { localStorageClient } from "../infrastructure/local-storage-client";
import { AudioPlayerClient } from "../infrastructure/audio-client/audio-player-client";
import { createSpeechClient } from "../infrastructure/audio-client/speech";
import { UiTemplateModule } from "./module/ui-template-module/ui-template-module";
import { GaClient } from "../infrastructure/ga-client/ga-client";
import { ActivityLogModule } from "@agp/shared.user-app/module/activity-log.module";
import { QuestionnaireRepository } from "@agp/shared.user-app/module/questionnaire.repository";

export default class ServiceLocator {
  public static ThemeStore: ThemeStore;
  public static MasterDataModule: MasterDataModule;
  public static LanguageRepository: LanguageRepository;
  public static LocalizedEntityRepository: LocalizedEntityRepository;
  public static ApplicationStateRepository: ApplicationStateRepository;
  public static GuidePlayerModule: GuidePlayerModule;
  public static PlayerLayoutModule: PlayerLayoutModule;
  public static PrefetchModule: PrefetchModule;
  public static UiTemplateModule: UiTemplateModule;
  public static ActivityLogModule: ActivityLogModule;
  public static QuestionnaireRepository: QuestionnaireRepository;

  public static initializeAsync = async () => {
    const apiModule = new UserAppApiModule(applicationConfig.apiUrl);
    this.MasterDataModule = new MasterDataModule(apiModule);
    const masterData = await this.MasterDataModule.fetch(
      applicationConfig.applicationId,
      previewConfig.isPreview
    );
    this.ThemeStore = new ThemeStore(
      masterData,
      colorSchemaConfig,
      previewConfig
    );

    const persistentDataModule = new PersistentDataModule(
      localStorageClient,
      masterData
    );
    this.LanguageRepository = new LanguageRepository(
      masterData,
      persistentDataModule
    );
    this.LocalizedEntityRepository = new LocalizedEntityRepository(
      this.LanguageRepository,
      masterData
    );
    this.ApplicationStateRepository = new ApplicationStateRepository(
      this.LocalizedEntityRepository,
      persistentDataModule
    );
    this.PrefetchModule = new PrefetchModule();
    this.PlayerLayoutModule = new PlayerLayoutModule({
      miniPlayerHeight: 90,
      middlePlayerHeight: window.innerWidth * 0.85 + 90,
      fullPlayerHeight: window.innerHeight * 0.96,
    });
    this.UiTemplateModule = new UiTemplateModule(
      applicationConfig.applicationId
    );

    const activityLogClient = new GaClient(
      masterData.application.name,
      "G-STQGQNER9R", // Stag
      // "G-P5HNCELFCV", // Test
      {
        send_page_view: false,
      }
    );
    this.ActivityLogModule = new ActivityLogModule(
      activityLogClient,
      masterData,
      this.LanguageRepository
    );

    this.GuidePlayerModule = new GuidePlayerModule(
      new AudioPlayerClient(),
      createSpeechClient(),
      this.LocalizedEntityRepository,
      this.ApplicationStateRepository,
      this.ActivityLogModule,
      masterData.languages,
      // TODO: 汎用化
      this.UiTemplateModule.uiTemplate === "Minpaku" ? "Area" : "Tour"
    );
    await this.PrefetchModule.fetchAsync(masterData);

    this.QuestionnaireRepository = new QuestionnaireRepository(
      localStorageClient
    );
  };
}
