import ServiceLocator from "../../../../../../domain/service-locator";
import Icon from "../../../../../components/icon";
import styled from "styled-components";
import { MinpakuSeekBar } from "../minpaku-seek-bar";

const getGuideNumberImagePath = (trackNumber: number) =>
  `/minpaku-demo/guide-number/${trackNumber}_list_200×150_minpaku_number.png`;

const MinpakuPlayerMiniComponent = () => {
  const guidePlayerModule = ServiceLocator.GuidePlayerModule;
  const playerLayoutModule = ServiceLocator.PlayerLayoutModule;
  const { theme } = ServiceLocator.ThemeStore;

  const isPlaying = guidePlayerModule.isPlaying.watch();
  const track = guidePlayerModule.currentTrack.watch();

  return (
    <>
      {track && (
        <div
          className={
            "items-stretch h-[90px] bg-white py-[4px] px-[20px] flex flex-col justify-between gap-[4px] rounded-t-[15px] relative"
          }
        >
          <div
            className={"absolute top-[10px] left-[50%] -translate-x-[50%]"}
            onClick={() => {
              playerLayoutModule.playerType.setValue("full");
            }}
          >
            <div
              className={
                "absolute bg-black rounded-full w-7 h-7 top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
              }
            />
            <img
              alt="expandable-button"
              src="/minpaku-demo/icons/expand-button.svg"
              className={`h-8 w-8 rotate-180`}
            />
          </div>
          <div className="flex justify-between flex-grow items-center">
            <div
              className={
                "flex items-center justify-start gap-[15px] flex-grow h-[48px] overflow-hidden"
              }
              onClick={() => {
                playerLayoutModule.playerType.setValue("full");
              }}
            >
              <MinThumbnail
                trackNumber={track.trackNumber}
                onClick={() => {
                  playerLayoutModule.playerType.setValue("full");
                }}
              />
              <div className="text-md h-[100%] flex items-end text-xl">
                <Title className={"whitespace-nowrap"}>
                  {track.trackNumber}. {track.name}
                </Title>
              </div>
            </div>
            <div
              className={"flex justify-end items-center w-28 shrink-0 gap-8"}
            >
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  guidePlayerModule.playOrPause();
                }}
                className={"w-8"}
              >
                <Icon
                  type={isPlaying ? "pause" : "play"}
                  color={theme.color.primaryText}
                />
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  guidePlayerModule.stop();
                }}
                className={"w-8"}
              >
                <img src="/minpaku-demo/icons/close.svg" className="w-full" />
              </div>
            </div>
          </div>
          <MinpakuSeekBar withTime={false} />
        </div>
      )}
    </>
  );
};

const MinThumbnail = ({
  trackNumber,
  onClick,
}: {
  trackNumber: number;
  onClick: () => void;
}) => {
  return (
    <div
      className={"overflow-hidden h-full rounded-[10px] flex-shrink-0"}
      style={{ filter: "drop-shadow(0 0 4px rgba(0, 0, 0, 0.1))" }}
      onClick={onClick}
    >
      <img
        src={getGuideNumberImagePath(trackNumber)}
        className={"object-contain h-full"}
      />
    </div>
  );
};

export default MinpakuPlayerMiniComponent;

const Title = styled.div`
  color: ${(props) => props.theme.color.primaryText};
`;
