import ServiceLocator from "../../../../domain/service-locator";
import { MinpakuTop } from "./minpaku/minpaku-top";

export const TopAcoustiguide = () => {
  const uiTemplateModule = ServiceLocator.UiTemplateModule;

  return (
    <>{uiTemplateModule.uiTemplate === "Minpaku" ? <MinpakuTop /> : <></>}</>
  );
};
