import { Guide, Tour } from "@agp/shared.user-app/entity";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import MinpakuGuideItem from "../../../containers/guide-item/acoustiguide/minpaku-guide-item";

export type DetailedTour = Tour & {
  guides: Guide[];
};

interface ExpandableTourCardProps {
  labelColor?: string;
  tour: DetailedTour;
  onClickGuide: (id: string) => void;
  currentPlayingGuideId?: string;
}

const listVariants: Variants = {
  visible: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  hidden: {
    transition: {
      duration: 0,
    },
  },
};

const itemVariants: Variants = {
  visible: {
    transition: {
      duration: 0.3,
    },
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

export const ExpandableTourCard = (props: ExpandableTourCardProps) => {
  const {
    open,
    toggle,
    firstState: isRounded,
    secondState: isExpanded,
  } = useTwoStepOpenState(200);

  const location = useLocation();
  const handleClickExpandButton = useCallback(() => {
    toggle();
  }, [toggle]);

  const [isScrolled, setIsScrolled] = useState(false);

  const containerId = useMemo(
    () => `container-${props.tour.id}}`,
    [props.tour]
  );

  const hashGuideId = useMemo(() => {
    const id = location.hash.replace("#", "");
    return props.tour.guides.map((x) => x.id).includes(id) ? id : null;
  }, [props.tour.guides, location.hash]);

  useEffect(() => {
    setIsScrolled(false);
    if (hashGuideId) open();
  }, [hashGuideId, open]);

  useEffect(() => {
    if (!hashGuideId || !isExpanded || isScrolled) return;

    const elem = document.getElementById(`guide-item-${hashGuideId}`);
    elem?.scrollIntoView({ behavior: "smooth" });

    setIsScrolled(true);
  }, [hashGuideId, containerId, props.tour.guides, isExpanded, isScrolled]);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!contentRef.current) return;
    if (isExpanded) {
      contentRef.current.style.height = contentRef.current.scrollHeight + "px";
    } else {
      contentRef.current.style.height = "0px";
    }
  }, [isExpanded]);

  return (
    <>
      <div
        className={twMerge(
          "relative w-full aspect-[4/1] overflow-hidden text-white text-3xl flex items-center justify-between pr-4 pl-10 font-bold transition-all duration-300",
          isRounded ? "rounded-t-3xl " : "rounded-3xl ",
          props.labelColor === "yellow"
            ? "bg-[#a39323]"
            : props.labelColor === "purple"
            ? "bg-[#705388]"
            : props.labelColor === "red"
            ? "bg-[#a93234]"
            : props.labelColor === "green"
            ? "bg-[#869e46]"
            : props.labelColor === "blue"
            ? "bg-[#3d6c98]"
            : props.labelColor === "gray"
            ? "bg-[#737373]"
            : "bg-[#bb5730]"
        )}
        onClick={handleClickExpandButton}
      >
        {props.tour.name}
        <img
          alt="expandable-button"
          src="/minpaku-demo/icons/expand-button.svg"
          className={`h-12 w-12 transform transition-transform duration-300 ${
            isExpanded ? "rotate-180" : "rotate-0"
          }`}
        />
      </div>

      <div
        className={
          "bg-[#bdc0c4] duration-300 overflow-hidden transition-[height] h-0 rounded-b-3xl"
        }
        ref={contentRef}
      >
        <AnimatePresence>
          {isExpanded && (
            <motion.div
              variants={listVariants}
              initial={"hidden"}
              animate={"visible"}
              className="py-3 mx-5"
            >
              {props.tour.guides.map((x, i) => (
                <motion.li
                  key={`guide-item-${i}`}
                  id={`guide-item-${x.id}`}
                  variants={itemVariants}
                  className="bg-[#f0e9b3] my-2"
                >
                  <MinpakuGuideItem
                    onClickGuide={props.onClickGuide}
                    guide={x}
                    active={props.currentPlayingGuideId === x.id}
                    isListened={false}
                  />
                </motion.li>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export const delay = (milliseconds: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });

const useTwoStepOpenState = (duration: number) => {
  const [firstState, setFirstState] = useState(false);
  const [secondState, setSecondState] = useState(false);

  const toggle = useCallback(() => {
    if (firstState && secondState) {
      setSecondState(false);
    }
    if (!firstState && !secondState) {
      setFirstState(true);
    }
  }, [firstState, secondState]);

  useEffect(() => {
    const propagate = async () => {
      await delay(duration);
      setSecondState(firstState);
    };
    if (firstState) propagate();
  }, [firstState]);

  useEffect(() => {
    const propagate = async () => {
      await delay(duration);
      setFirstState(secondState);
    };
    if (!secondState) propagate();
  }, [secondState]);

  return {
    open: () => setFirstState(true),
    close: () => setSecondState(false),
    toggle,
    firstState,
    secondState,
  };
};
